<template>
  <vue-apex-charts
    :options="options"
    :series="series"
    :height="height"
    :type="type"
  />
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  components: { VueApexCharts },
  props: {
    series: {
      type: Array,
      default: () => [],
      required: true
    },
    height: {
      type: String,
      default: '',
      required: true
    },
    type: {
      type: String,
      default: '',
      required: true
    },
    options: {
      type: Object,
      default: () => {},
      required: true
    },
  }
}
</script>
